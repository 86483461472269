import { useRouter } from 'next/router';
import Link from 'next/link';

const FacetValue = ({ facetValue, facetCount, field, sourcePath }) => {
    //console.log("Executing FacetValue")
    const router = useRouter();
    if (facetAlreadySelected(router.query, field, facetValue)) {
        return <FacetValueSelected sourcePath={sourcePath} facetValue={facetValue} field={field} query={router.query} />;
    } else {
        return <FacetValueNotSelected sourcePath={sourcePath} facetValue={facetValue} facetCount={facetCount} field={field} query={router.query} />;
    }
};

function facetAlreadySelected(query, field, value) {
    //console.log("Executing facetAlreadySelected for field = " + field)
    if (!query.hasOwnProperty(field)) {
        return false;
    }
    if (Array.isArray(query[field])) {
        if (query[field].indexOf(value) === -1) {
            return false;
        }
    } else if (query[field] !== value) {
        return false;
    }
    return true;
}

const FacetValueNotSelected = ({ facetValue, facetCount, field, query, sourcePath }) => {
    const path = buildAddFacetValueLink(query, field, facetValue, sourcePath);
    return (
        <li>
            <Link href={path}>
                <a>{facetValue}</a>
            </Link>
            {'\u00A0'}({facetCount})
        </li>
    );
};

const FacetValueSelected = ({ facetValue, field, query, sourcePath }) => {
    const path = buildRemoveFacetValueLink(query, field, sourcePath);
    return (
        <li>
            {facetValue}
            {'\u00A0'}(
            <Link href={path}>
                <a>X</a>
            </Link>
            )
        </li>
    );
};

function buildAddFacetValueLink(query, field, value, sourcePath) {
    //console.log("Executing buildAddFacetValueLink.")
    // When adding a new facet value, reset start to 1.
    let link = sourcePath + '?';
    for (const [key, value] of Object.entries(query)) {
        switch (key) {
            case 'page':
                link += 'page=1';
                break;
            default:
                link += key + '=' + encodeURIComponent(value);
        }
        link += '&';
    }
    link += field + '=' + encodeURIComponent(value);
    return link;
}

function buildRemoveFacetValueLink(query, field, sourcePath) {
    //console.log("Executing buildRemoveFacetValueLink.")
    // When removing a facet value, reset start to 1.
    let link = sourcePath + '?';
    for (const [key, value] of Object.entries(query)) {
        switch (key) {
            case 'page':
                link += 'page=1';
                break;
            case field:
                // do not add to link
                break;
            default:
                link += key + '=' + encodeURIComponent(value);
        }
        link += '&';
    }
    link = link.slice(0, link.length - 1);
    return link;
}

export default FacetValue;
