import { deleteProductVersion } from '../../products';
import Link from 'next/link';
import { useRef, Fragment } from 'react';
import DeleteConfirmationDialog from '../../productVersionEditor/DeleteConfirmationDialog';
import { showModal } from '../../utils';
import { useUserContext } from '../../../components/userContext';

const ListSearchResult = ({ result, index, sourcePath, page, rows }) => {
    function buildTitle() {
        let title = '';
        if (result.product_type === 'data activity' && result.dcterms_location) {
            title += result.dcterms_location + ' - ';
        }
        if (result.dcterms_title) {
            title += result.dcterms_title;
        } else {
            title += 'Untitled Product';
        }
        return (
            <Link href={'/products/' + result.product_version_id} passHref>
                <a href='#'>{title}</a>
            </Link>
        );
    }

    return (
        <tr>
            <td>{(page - 1) * rows + Number(index) + 1}.</td>
            <td data-label='Cat. ID'>{result.product_id}</td>
            <td data-label='Eval. ID'>{result.imeta_evaluationId ? result.imeta_evaluationId : '-'}</td>
            <td data-label='Title'>{buildTitle()}</td>
            <td data-label='Version Label'>{result.version_label}</td>
            <td data-label='Product Type'>{result.product_type}</td>
            <td data-label='Status'>{result.status}</td>
            <td data-label='Assignments'>{result.participants ? result.participants.join(', ') : '-'}</td>
            <td data-label='Actions'>
                <ProductVersionActions result={result} sourcePath={sourcePath} />
            </td>
        </tr>
    );
};

const ProductVersionActions = ({ result, sourcePath }) => {
    const user = useUserContext();
    let userIcpsrPermitted = user.roles.find((r) => r.roleLabel === 'mcc-icpsr-permitted');
    const modalRef = useRef();

    const deleteRow = () => {
        showModal(modalRef);
    };

    const onDeleteConfirm = () => {
        deleteProductVersion(result.product_version_id, sourcePath);
    };
    const deleteAction = //only appears if product is unpublished and user is mcc-icpsr-permitted
        userIcpsrPermitted && user.isMccIcpsrPermitted === true && result.status != 'published' ? (
            <>
                <a href='#' onClick={deleteRow}>
                    delete
                </a>
                <br />
                <em>for testing/admin only</em>
            </>
        ) : (
            <></>
        );

    return (
        <Fragment>
            {deleteAction}
            {
                <DeleteConfirmationDialog ref={modalRef} title={'Delete Confirmation'} onConfirm={onDeleteConfirm}>
                    Are you sure you want to delete this product?
                </DeleteConfirmationDialog>
            }
        </Fragment>
    );
};
export default ListSearchResult;
